import "./Markdown.css";

import React, { ReactElement } from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  content: string;
};

export default function Markdown(props: Props): ReactElement<Props> {
  const { className, content } = props;
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className={classnames("markdown-body", className)}
    />
  );
}
