import styles from "./BlogPostTemplate.module.css";

import React, { ReactElement } from "react";
import { graphql, Link } from "gatsby";
import Image, { FixedObject } from "gatsby-image";

import Badge from "../components/Badge";
import IconBack from "../components/IconBack";
import IconGithub from "../components/IconGithub";
import IconLinkedin from "../components/IconLinkedin";
import IconTwitter from "../components/IconTwitter";
import IconLink from "../components/IconLink";
import Layout from "../components/Layout";
import Markdown from "../components/Markdown";
import SEO from "../components/SEO";
import Text from "../components/Text";

type Props = {
  data: {
    markdownRemark: {
      id: string;
      html: string;
      timeToRead: number;
      frontmatter: {
        title: string;
        date: string;
        description: string;
        tags: string[];
        github?: string;
        url?: string;
      };
    };
    avatar: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    site: {
      siteMetadata: {
        social: {
          github: string;
          linkedin: string;
          twitter: string;
        };
      };
    };
  };
};

export default function BlogPostTemplate(props: Props): ReactElement<Props> {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        description,
        date,
        tags,
        github: projectGithub,
        url: projectUrl,
      },
      timeToRead,
      html,
    },
    avatar: {
      childImageSharp: { fixed: avatarFixed },
    },
    site: {
      siteMetadata: {
        social: { twitter, github, linkedin },
      },
    },
  } = props.data;

  return (
    <Layout>
      <SEO title={title} description={description} />
      <article>
        <header>
          <Text weight={600} size={14}>
            <Link to="posts" className={styles.backLink}>
              <IconBack className={styles.backLinkIcon} />
              BACK TO ALL POSTS
            </Link>
          </Text>
          <Text tag="h1" size={32} weight={700} className={styles.title}>
            {title}
          </Text>
          <div className={styles.subtitle}>
            <Text size={14} className={styles.date}>
              {`${date} • ${timeToRead} min read`}
            </Text>
            {projectGithub != null ? (
              <a
                href={projectGithub}
                className={styles.subtitleLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconGithub className={styles.subtitleLinkIcon} />
              </a>
            ) : null}
            {projectUrl != null ? (
              <a
                href={projectUrl}
                className={styles.subtitleLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconLink className={styles.subtitleLinkIcon} />
              </a>
            ) : null}
          </div>
        </header>
        <section>
          <Markdown content={html} className={styles.content} />
          <div className={styles.tags}>
            {tags.map((tag, index) => (
              <Link
                to={`/tag/${tag
                  .toLowerCase()
                  .split(" ")
                  .join("-")}/`}
                key={index}
                className={styles.tag}
              >
                <Badge text={tag} />
              </Link>
            ))}
          </div>
        </section>
        <hr className={styles.hr} />
        <footer className={styles.footer}>
          <div className={styles.footerAvatarWrapper}>
            <Image fixed={avatarFixed} className={styles.footerAvatar} />
          </div>
          <div className={styles.footerContent}>
            <Text>
              From{" "}
              <a
                href={`https://twitter.com/${twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Bui</strong>
              </a>{" "}
              with 💙
            </Text>
            <div className={styles.iconContainer}>
              <a
                href={`https://twitter.com/${twitter}`}
                className={styles.iconLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconTwitter className={styles.icon} />
              </a>
              <a
                href={`https://www.linkedin.com/in/${linkedin}/`}
                className={styles.iconLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconLinkedin className={styles.icon} />
              </a>
              <a
                href={`https://github.com/${github}`}
                className={styles.iconLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconGithub className={styles.icon} />
              </a>
            </div>
          </div>
        </footer>
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        description
        tags
        github
        url
      }
    }
    avatar: file(absolutePath: { regex: "/ava.png/" }) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          github
          linkedin
        }
      }
    }
  }
`;
